.departmentRowHomeHeader{
    height: 40px;
    /* width: 100%; */
    position: static;
    float: none;
    display: flex;

}
/* .departmentRowHomeHeaderContainer{
    margin-top: 0.5rem;
    width: 98%;
    margin-left: auto;
    margin-right: auto;
} */
.navbarToggleIcon{
    color: black;
    font-weight: 900;
    font-size: 1.5rem;
    cursor: pointer;
    float: left;
}
.sidebarSection{
    position: fixed;
    overflow: hidden;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    background: rgba(36, 41, 46, 0.9);
    transition: 0.3s;
    z-index: 999;
    
}
.sidebarContainer{
    width: 50%;
    height: auto;
    z-index: 1000;
    display: flex;
    position: fixed;
    background-color: black;
    overflow: auto;
}
.sidebarBody{
    width: 100%;
    max-height:100vh ;
    overflow: auto;
    /* height: auto; */
    /* overflow: hidden; */
    scroll-behavior: smooth;
    background-color: white;
    /* padding-top: 4.5rem; */
}
.sidebarBodyTitle{
    font-size: 18px;
    font-weight: 700;
    line-height: 24px;
    color: #111;
    margin-left: 2.5rem;
}
.sidebarBodyTextDiv{
    cursor: pointer;
    height: 3rem;
}
.sidebarBodyTextDiv{
    cursor: pointer;
    height: 3rem;
}
.departmentRowHomeHeaderContentText{
    color: white;
    font-size: 14px;
    margin-top: 0.2rem;
    float: left;
    margin-left: 1rem;
    cursor: pointer;
}
.departmentRowHomeHeaderContentText:hover{
    border: 1px solid white;
}

.menu{
    display: none;
}