.entire-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  /* padding-top: 50px; */
  background-color: #f6f5f5;
}

.cart-item-img {
  width: 55px;
  cursor: pointer;
  transition: all 0.3s ease;
}

.cart-item-img:hover {
  transform: scale(1.1);
}

.checkout-section {
  display: block;
  margin-top: 20px;
  background-color: white;
  margin-left: 25px;
  max-width: fit-content;
  padding: 15px;
  padding-left: 30px;
  padding-right: 30px;
  height: fit-content;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 6px -1px,
    rgba(0, 0, 0, 0.06) 0px 2px 4px -1px;
  background-color: white;
  border-radius: 10px;
}

.cart-section {
  display: flex;
  justify-content: center;
  width: 70%;
  height: 30vh;
  position: relative;
  top: 25px;
  margin-bottom: 125px;
}
.cart-data {
  display: flex;
  align-items: center;
  max-width: 450px;
  padding-top: 25px;
  padding-bottom: 25px;
  padding-left: 30px;
  padding-right: 30px;
  height: auto;
  background-color: white;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 6px -1px,
    rgba(0, 0, 0, 0.06) 0px 2px 4px -1px;
  border-radius: 10px;
  margin-top: 20px;
}
.cart-head {
  font-family: "Poppins";
  font-size: 30px;
  padding-top: 20px;
  font-weight: bolder;
}
.cart-title {
  margin: 0;
}

.cart-all-data {
  margin-left: 20px;
}
.quantity-section {
  display: flex;
  align-items: center;
}
.increase {
  margin-right: 15px;
}
.decrease {
  margin-left: 15px;
}

.cart-title {
  font-family: "Poppins";
  font-size: 16px;
  color: rgb(38, 38, 38);
}
.cart-discount {
  font-size: 18px;
  font-weight: 580;
  font-family: "Poppins";
  margin-top: 12px;
  margin-bottom: 0px;
}

.quantity-section {
  width: -moz-fit-content;
  width: fit-content;
  height: -moz-fit-content;
  height: 30px;
  margin-top: 20px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 3px 0px,
    rgba(0, 0, 0, 0.06) 0px 1px 2px 0px;
  padding: 4px;
  border-radius: 6px;
}

.increase,
.decrease {
  cursor: pointer;
  border: 0;
  background-color: white;
  font-size: 22px;
}

.quantity-section > p {
  font-size: 17px;
}

.cart-size {
  font-family: "Poppins";
}

.more-buttons {
  display: flex;
  align-items: center;
}
.more-buttons p{
  margin-bottom: 0px;
}

.save-img {
  cursor: pointer;
  width: 22px;
  transition: all 0.15s ease;
}

.save-img:hover {
  transform: scale(1.18);
}

.save-img:active {
  transform: scale(1);
}

.delete-img {
  cursor: pointer;
  transition: all 0.15s ease;
  width: 24px;
}

.delete-img:hover {
  transform: scale(1.18);
}

.delete-img:active {
  transform: scale(1);
}

.right-btns {
  margin-top: 20px;
  margin-left: 45px;
  font-family: "Poppins", sans-serif;
  display: flex;
}
.save-btn,
.delete-btn {
  margin-left: 15px;
  display: flex;
  align-items: center;
}
.save-btn > p,
.delete-btn > p {
  margin-left: 6px;
}

.empty-cart-img {
  margin-top: 10px;
  width: 350px;
}

.empty-cart {
  animation: fadein 0.4s ease;
}

.congrats > p {
  color: #013d01;
  font-family: "Poppins";
  padding: 8px;
  border-radius: 6px;
  font-size: 15px;
  background-color: #0cd80c62;
}

.applied,
.applied2 {
  font-family: "Poppins";
  color: rgb(156, 0, 0);
  font-size: 15px;
}

.promocode {
  display: flex;
  justify-content: center;
}

.promocode > input {
  outline: none;
  border: 1.5px solid rgb(193, 193, 193);
  padding: 10px;
  font-size: 16px;
  font-family: "Poppins";
  border-radius: 5px;
  width: 230px;
  height: 25px;
}

.promocode-btn {
  display: flex;
  align-items: center;
  cursor: pointer;
  border: none;
  background-color: #1c2228;
  padding-left: 12px;
  padding-right: 12px;
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  color: white;
  transition: all 0.3s ease;
  font-size: 17px;
  border-radius: 5px;
  margin-left: 6px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 18px 50px -10px;
  transition: all 0.13s;
}

.promocode-btn:hover {
  transform: scale(1.06);
}

.promocode-btn:active {
  transform: scale(1);
}

.payment {
  cursor: pointer;
  height: 50px;
  padding: 3px;
  border: none;
  background-color: rgb(255, 149, 0);
  padding-left: 22px;
  padding-right: 22px;
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  color: white;
  transition: all 0.3s ease;
  font-size: 18px;
  border-radius: 5px;
  transition: all 0.13s;
  margin-bottom: 14px;
  margin-top: 18px;
}

.payment:hover {
  transform: scale(1.05);
}

.payment:active {
  transform: scale(1);
}

.money-data {
  font-family: "Poppins";
  display: flex;
  flex-direction: column;
}

.payment-btn {
  display: flex;
  flex-direction: column;
}

.total,
.total-price,
.total-price2 {
  margin: 0;
  font-weight: bolder;
  font-size: 18px;
}

.money-1,
.money-2,
.money-3,
.money-4,
.money-5 {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.money-2,
.money-3,
.money-4 {
  font-size: 13.5px;
  color: rgb(92, 92, 92);
}

.money-2 > p,
.money-3 > p,
.money-4 > p {
  margin-bottom: 0px;
}

.money-5 {
  font-weight: bolder;
  font-family: "Poppins";
  font-size: 18px;
}

/* MEDIA QUERIES */

@media (max-width: 900px) {
  .cart-section {
    flex-direction: column;
    align-items: center;
  }
  .checkout-section {
    margin-left: 0px;
    margin-top: 40px;
  }
}

@media (max-width: 476px) {
  .cart-discount {
    width: fit-content;
  }

  .total,
  .total-price,
  .total-price2 {
    font-size: 16px;
  }

  .congrats > p {
    font-size: 13.6px;
  }

  .payment,
  .promocode-btn {
    font-size: 15.6px;
  }

  .cart-size {
    font-size: 13.6px;
  }

  .cart-details {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .cart-title {
    font-size: 13.8px;
    width: 78%;
  }
  .cart-discount {
    font-size: 15.5px;
  }
  .increase,
  .decrease {
    font-size: 17.5px;
  }
  .quantity-section > p {
    font-size: 15px;
  }
  .save-btn > p,
  .delete-btn > p {
    display: none;
  }
  .promocode > input {
    width: 186px;
  }
}

@media (max-width: 400px) {
  .cart-data {
    flex-direction: column;
  }
  .cart-all-data {
    margin-top: 32px;
  }
  .cart-item-img {
    width: 80px;
  }
  .promocode > input {
    width: 135px;
  }
}

@media (max-width: 318px) {
  .right-btns {
    margin-left: 18px;
  }
  .promocode > input {
    width: 108px;
  }
}

@media (max-width: 280px) {
  .cart-item {
    width: 89%;
  }
  .promocode > input {
    width: 82px;
  }
}

@media (max-width: 450px) {
  .empty-cart-img {
    width: 290px;
  }
}

@media (max-width: 300px) {
  .empty-cart-img {
    width: 240px;
  }
}
