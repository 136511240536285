
.adminPanelSectionContainer {
  width: 100%;
  height: auto;
  overflow: auto;
  background-color: #e9eef7;
  padding-bottom: 1rem;
  padding-top: 5rem;
}
.adminPanelOverviewSection {
  width: 90%;
  height: auto;
  margin-left: auto;
  margin-right: auto;
}
.adminPanelOverviewContainer {
  margin-top: 0rem;
}

.adminPanelOverviewTitle {
  font-weight: 400;
  font-size: 28px;
  line-height: 36px;
  color: #0f1111;
}
.adminPanelOverviewSubTitleDiv {
  margin-top: 0.5rem;
}
.adminPanelOverviewSubTitleText {
  display: flex;
  color: #8798ad;
  font-size: 12px;
  text-shadow: none;
}
.adminPanelOverviewSubDashboardTitle {
  margin-right: 0.5rem;
  cursor: pointer;
  color: #8798ad;
}
.adminPanelOverviewSubAmazonDashboardTitle {
  margin-left: 0.5rem;
  margin-right: 0.5rem;
  cursor: pointer;
  color: #8798ad;
}
.adminPanelOverviewBoxContainer {
  margin-top: 1rem;
  width: 100%;
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: auto;
  margin-right: auto;
}
.adminPanelOverviewOrdersBoxContainerDiv {
  width: 320px;
  min-height: 102px;
  height: auto;
  display: block;
  border-radius: 8px;
  background-color: #fff;
  border: 1px #d5d9d9 solid;
  float: left;
  cursor: pointer;
}
.adminPanelOverviewOrdersBoxContainerDiv:hover {
  background-color: #eeeeee;
}
.adminPanelOverviewOrdersBoxDiv {
  width: 90%;
  height: 90%;
  margin-left: auto;
  margin-right: auto;
  margin-top: 1rem;
}
.adminPanelOverviewOrdersImgDivContainer {
  float: left;
}
.adminPanelOverviewOrdersImg {
  padding-top: 1rem;
  width: 66px;
  height: auto;
}
.adminPanelOverviewOrdersTextDivContainer{
  float: left;
}
.adminPanelOverviewOrdersText {
  color: #111;
  font-size: 17px;
  font-weight: 400;
  font-style: normal;
  text-transform: none;
  text-decoration: none;
  cursor: pointer;
}
.adminPanelOverviewOrdersSubText {
  color: #565959;
  cursor: pointer;
  font-size: 14px;
}
.adminPanelOverviewOrdersTextDivContainer {
  margin-left: .75rem;
  float: left;
}

.adminPanelOverviewUsersBoxContainerDiv {
  width: 320px;
  min-height: 102px;
  height: auto;
  display: block;
  border-radius: 8px;
  background-color: #fff;
  border: 1px #d5d9d9 solid;
  float: left;
  cursor: pointer;
}
.adminPanelOverviewUsersBoxContainerDiv:hover {
  background-color: #eeeeee;
}
.adminPanelOverviewUsersBoxDiv {
  width: 90%;
  height: 90%;
  margin-left: auto;
  margin-right: auto;
  margin-top: 1rem;
}
.adminPanelOverviewUsersImgDivContainer {
  float: left;
}
.adminPanelOverviewUsersImg {
  width: 66px;
  height: auto;
}
.adminPanelOverviewUsersText {
  color: #111;
  font-size: 17px;
  font-weight: 400;
  font-style: normal;
  text-transform: none;
  text-decoration: none;
  line-height: 32px;
  cursor: pointer;
}
.adminPanelOverviewUsersSubText {
  color: #565959;
  cursor: pointer;
  font-size: 14px;
  line-height: 20px;
}
.adminPanelOverviewUsersTextDivContainer {
  margin-left: 1rem;
  float: left;
}

.adminPanelOverviewProductsBoxContainerDiv {
  width: 320px;
  min-height: 102px;
  height: auto;
  display: block;
  border-radius: 8px;
  background-color: #fff;
  border: 1px #d5d9d9 solid;
  float: left;
  cursor: pointer;
}
.adminPanelOverviewProductsBoxContainerDiv:hover {
  background-color: #eeeeee;
}
.adminPanelOverviewProductsBoxDiv {
  width: 90%;
  height: 90%;
  margin-left: auto;
  margin-right: auto;
  margin-top: 1rem;
}
.adminPanelOverviewProductsImgDivContainer {
  float: left;
}
.adminPanelOverviewProductsImg {
  width: 66px;
  height: auto;
}
.adminPanelOverviewProductsText {
  color: #111;
  font-size: 17px;
  font-weight: 400;
  font-style: normal;
  text-transform: none;
  text-decoration: none;
  line-height: 32px;
  cursor: pointer;
}
.adminPanelOverviewProductsSubText {
  color: #565959;
  cursor: pointer;
  font-size: 14px;
  line-height: 20px;
}
.adminPanelOverviewProductsTextDivContainer {
  margin-left: 1rem;
  float: left;
}
.container-fluid {
  width: 100%;
  padding-right: 0px;
  padding-left: 0px;
  margin-right: auto;
  margin-left: auto;
  overflow: hidden;
}
