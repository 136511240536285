.adminLoginContainer{
    width: 100%;
    height: 39rem;

}
.imgLogo{
    width: 30%;
    height: auto;
    margin-left: 35%;
}
.logincontainer{
    width: 40%;
    height: 20rem;
    margin-left: auto;
    margin-right: auto;
    border-radius: 8px 8px 0 0;
    border: 1px #D5D9D9 solid;
}
.signinContainer{
    width: 100%;
    height: 4rem;
    background-color: #F0F2F2;
}
.AdminText{
    font-size: xx-large;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    font-weight: 300;
    padding-left: 1rem;
    padding-top: 1rem;
    float: left;
    color: #565959;
}
.signInText{
    font-size: xx-large;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    font-weight: 300;
    text-align: right;
    margin-right: 1rem;
    padding-top: 1rem;
    color: #333;
}
.fa-user{
    float: left;
    margin-left: -164px;
    padding-top: 56px;
}
.usernameInput{
    margin-left: 50px;
    margin-top: 2rem;
    width: 80%;
    border-radius: 3px;
    font-family: Arial,sans-serif;
    font-size: 13px;
    border: 1px solid #d0d0d0;
    border-top: 1px solid #bbb;
    outline-color: #f69b3c;
    outline-width: thin;
    height: 2rem;
}
.fa-lock{
    margin-top: 2.5rem;
    margin-left: 2rem;
    float: left;
}
.passwordInput{
    margin-left: 5px;
    margin-top: 2rem;
    width: 80%;
    height: 2rem;
    border-radius: 3px;
    font-family: Arial,sans-serif;
    font-size: 13px;
    border: 1px solid #d0d0d0;
    border-top: 1px solid #bbb;
    outline-color: #f69b3c;
    outline-width: thin;
}
.btn-login{
    margin-left: auto;
    margin-right: 3.5rem;
    margin-top: 3rem;
    width: 150px;
    height: 31px;
    color: #111;
    text-align: center;
    font-size: 13px;
    display: block;
    font-family: Arial,sans-serif;
    white-space: nowrap;
    background-color: transparent;
    background-color: #f0c24f;
    border-radius: 3px;
    border: 1px solid;
    border-color: #bf942b;
    outline: #f69b3c;
    cursor: pointer;
    margin-bottom: 2rem;
}
.adminLoginReturn{
    margin-top: 4rem;
    margin-left: 3.5rem;
    color: #007185;
    font-size: 14px;
    line-height: 20px;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    cursor: pointer;
    float: left;
}
.adminLoginReturn:hover{
    color: #c45500;
    text-decoration: underline;
}