.profile-section {
  background-color: #f6f5f5;
  padding-top: 180px;
  padding-bottom: 140px;
  height: fit-content;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.account-section {
  width: 64%;
}

.top-section {
  height: 42px;
  display: flex;
  padding-bottom: 12px;
  border-bottom: 2px solid rgba(220, 220, 220, 0.566);
  align-items: center;
  justify-content: space-between;
}

.welcome-mssg {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-family: "Poppins";
  font-weight: bolder;
  font-size: 32px;
}

.profile-name {
  font-family: "Poppins";
  margin: 0;
  margin-top: 20px;
  font-weight: bold;
  font-size: 20px;
}

.profile-email {
  margin: 0;
  margin-top: 3px;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  font-size: 17px;
  font-weight: 500;
  color: rgb(157, 157, 157);
}

.profile-img {
  width: 135px;
  padding: 3px;
  border: 4px solid #e99317;
  border-radius: 50%;
}

.left-account-section {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.account-section2 {
  margin-top: 50px;
  display: flex;
  justify-content: space-around;
}

.signout-btn {
  margin-top: 25px;
  cursor: pointer;
  display: flex;
  align-items: center;
  background-color: rgb(250, 55, 55);
  border: none;
  color: white;
  padding: 8px;
  font-family: "Poppins";
  font-size: 16px;
  font-weight: bold;
  border-radius: 5px;
  box-shadow: rgba(17, 12, 46, 0.15) 0px 48px 100px 0px;
  transition: all 0.2s ease;
}

.signout-btn:hover {
  transform: scale(1.05);
}

.signout-btn:active {
  transform: scale(0.92);
}
.personal-info-head {
  margin: 0;
  font-family: "Poppins";
  font-size: 32px;
  font-weight: bold;
}

.personal-info-desc {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  font-size: 17px;
  font-weight: 500;
  color: rgb(97, 97, 97);
}
.user-photo,
.mail-photo {
  width: 30px;
}
.name-section,
.mail-section {
  font-family: "Poppins";
  font-size: 18px;
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.personal-user-data {
  padding-top: 20px;
  display: grid;
  grid-template-columns: repeat(2, 260px);
  /* grid-template-rows: repeat(auto-fill, 450px); */
  grid-gap: 10px; /* adjust as needed */
}

.users-name,
.users-mail {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  font-size: 17px;
  font-weight: 500;
  color: rgb(145, 145, 145);
}

.personal-name,
.personal-mail {
  min-width: 220px;
  max-width: fit-content;
  padding-left: 25px;
  padding-right: 25px;
  padding-bottom: 25px;
  padding-top: 5px;
  background-color: white;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 6px -1px,
    rgba(0, 0, 0, 0.06) 0px 2px 4px -1px;
  border-radius: 12px;
}

.personal-mail {
  margin-left: 15px;
}

.personal-name > p,
.personal-mail > p {
  margin: 0;
}

.left-account-section {
  margin-right: 50px;
}

/* MEDIA QUERIES */

@media (max-width: 870px) {
  .personal-user-data {
    grid-template-columns: repeat(1, 260px);
  }
  .personal-mail {
    margin-left: 0px;
  }
}

@media (max-width: 720px) {
  .welcome-mssg,
  .personal-info-head {
    font-size: 27px;
  }
  .personal-info-desc {
    font-size: 15px;
  }
}

@media (max-width: 580px) {
  .profile-img {
    width: 110px;
  }
  .personal-info-head {
    display: none;
  }
  .personal-info-desc {
    display: none;
  }
  .account-section2 {
    flex-direction: column;
    align-items: center;
  }
  .left-account-section {
    margin-right: 0px;
    align-items: center;
    margin-bottom: 30px;
  }
  .welcome-mssg {
    font-size: 25px;
    border-bottom: 2px solid rgba(220, 220, 220, 0.566);
  }
  .top-section {
    border: none;
  }
}

@media (max-width: 330px) {
  .personal-name, .personal-mail{
    min-width: 205px;
  }
  
}