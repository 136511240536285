.signin-page {
  background-color: #f6f5f5;
  height: 100vh;
  animation: fadein 0.35s ease;
}

@keyframes fadein {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.amazon-logo {
  width: 160px;
}

.login-navbar {
  display: flex;
  align-items: center;
  padding-left: 80px;
  padding-right: 80px;
  justify-content: space-between;
}

.signup-btn,
.signin-btn {
  cursor: pointer;
  border: none;
  background-color: rgb(255, 149, 0);
  padding: 12px;
  padding-left: 22px;
  padding-right: 22px;
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  color: white;
  transition: all 0.3s ease;
  font-size: 18px;
  border-radius: 7px;
}

.signin-btn {
  margin-top: 25px;
}

.signin-btn:hover,
.signup-btn:hover {
  background-color: rgb(255, 102, 0);
}

.user-details {
  display: flex;
  flex-direction: column;
}

.some-text {
  text-align: center;
}

.login-form {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: white;
  padding: 50px;
  border-radius: 15px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 6px -1px,
    rgba(0, 0, 0, 0.06) 0px 2px 4px -1px;
}

.main-form {
  display: flex;
  justify-content: center;
  position: relative;
  bottom: 30vw;
  padding-bottom: 22px;
}

.main-form2 {
  display: flex;
  justify-content: center;
  position: relative;
  bottom: 32vw;
  padding-bottom: 22px;
}

.background {
  display: flex;
  align-items: center;
  position: relative;
  top: 60px;
  justify-content: space-between;
}

.BG1,
.BG2 {
  width: 40vw;
  object-fit: cover;
}

.user {
  margin: 0;
  font-family: "Noto Sans", sans-serif;
  font-size: 35px;
}

.user-desc {
  font-family: "Poppins", sans-serif;
  width: 300px;
}

.or {
  font-family: "Poppins", sans-serif;
  text-align: center;
  font-weight: 600;
}

.email,
.password,
.name {
  height: 35px;
  width: 350px;
  font-family: "Poppins", sans-serif;
  margin-top: 10px;
  outline: none;
  border: 1px solid rgba(175, 175, 175, 0.678);
  padding: 10px;
  border-radius: 6px;
}

.extra-buttons {
  display: flex;
  flex-direction: column;
}

.google {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border: 1.5px solid rgba(175, 175, 175, 0.678);
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  background-color: white;
  color: rgba(0, 0, 0, 0.724);
  font-size: 18px;
  border-radius: 7px;
  transition: all 0.3s ease;
}

.google:hover {
  background-color: rgb(226, 226, 226);
}

.google-img {
  width: 30px;
  margin-left: 10px;
}

.error-message {
  margin-top: 10px;
  width: 85%;
  font-family: "Poppins", sans-serif;
  color: red;
  font-size: 14px;
}

/* MEDIA QUERIES  */

@media (max-width: 1200px) {
  .BG1,
  .BG2 {
    position: relative;
    top: 7vw;
  }
}

@media (max-width: 768px) {
  .BG1,
  .BG2 {
    position: relative;
    top: 12vw;
  }
}

@media (max-width: 640px) {
  .BG1,
  .BG2 {
    opacity: 0;
  }

  .main-form {
    bottom: 25vw;
  }

  .main-form2 {
    bottom: 26vw;
  }
}

@media (max-width: 530px) {
  .login-form {
    padding: 30px;
  }

  .main-form {
    bottom: 17vw;
  }
  .main-form2 {
    bottom: 24vw;
  }
}

@media (max-width: 460px) {
  .main-form {
    bottom: 20vw;
  }
  .signup-btn,
  .signin-btn {
    padding: 8px;
  }
  .main-form2 {
    bottom: 20vw;
  }
  .login-navbar {
    padding-left: 25px;
    padding-right: 25px;
    justify-content: space-between;
  }
  .amazon-logo {
    width: 110px;
  }
  .email,
  .password,
  .name {
    width: 260px;
  }
  .user {
    font-size: 30px;
  }
  .user-desc {
    font-size: 15px;
  }
}

@media (max-width: 400px) {
  .main-form {
    bottom: 12vw;
  }
  .main-form2 {
    bottom: 10vw;
  }

  .email,
  .password,
  .name {
    width: 220px;
  }
  .login-form {
    padding-left: 18px;
    padding-right: 18px;
  }
}

@media (max-width: 350px) {
  .signin-page {
    background-color: white;
    padding-top: 20px;
  }
  .login-form {
    box-shadow: none;
  }
}

@media (max-width: 310px) {
  .email,
  .password,
  .name {
    width: 205px;
  }

  .main-form2 {
    bottom: 14vw;
  }

  .some-text {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .user-desc {
    width: 75%;
  }
}
