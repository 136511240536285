.adminOrderDetailsSection{
    padding-top: 5rem;
    width: 100%;
    height: auto;
    overflow: auto;
    margin-bottom: 2rem;
}
.orderDetailsProductContentOrderDiv{
    width: 50%;
    
}
.orderHistorySection{
    width: 100%;
    min-height: 550px;
    height: auto;
    overflow: auto;
}
.orderHistoryContainer{
    width: 80%;
    height: auto;
    margin-top: 0.5rem;
    margin-left: auto;
    margin-right: auto;
}
.orderHistoryAccountText{
    color: #555;
    cursor: pointer;
    font-family: "Amazon Ember",Arial,sans-serif;
    font-size: 14px;
    line-height: 20px;
}
.orderHistoryYourAccountText{
    color: #007185;
    cursor: pointer;
    font-family: "Amazon Ember",Arial,sans-serif;
    font-size: 14px;
    line-height: 20px;
    padding-right: 0.5rem;
}
.orderHistoryYourOrdersText{
    color: #c45500;
    cursor: pointer;
    font-family: "Amazon Ember",Arial,sans-serif;
    font-size: 14px;
    line-height: 20px;
    padding-left: 0.5rem;
}
.orderHistoryYourAccountText:hover{
    color: #c45500;
    text-decoration: underline;
}
.orderHistoryTitleDiv{
    margin-top: 1rem;
    margin-bottom: 1rem;
}
.orderHistoryTitle{
    font-family: "Amazon Ember",Arial,sans-serif;
    color: #0F1111;
    font-weight: 400;
    font-size: 28px;
    line-height: 36px;
}
.orderHistoryOrderNumberDiv{
    margin-top: 1rem;
}
.orderHistoryOrderNumber{
    font-weight: 700;
    font-style: normal;
    text-transform: none;
    font-family: "Amazon Ember",Arial,sans-serif;
    color: #0F1111;
    font-size: 14px;
    line-height: 20px;
}
.orderHistoryOrderNumberText{
    font-size: 14px;
    line-height: 20px;
    font-weight: 400;
    font-style: normal;
    text-transform: none;
    text-decoration: none;
}
.orderHistoryProductContainer{
    margin-top: 1rem;
    width: 90%;
    height: auto;
    border-radius: 8px 8px 0 0;
    border: 1px #D5D9D9 solid;
    margin-bottom: 1rem;
}
.orderHistoryProductHeadContainer{
    background-color: #F0F2F2;
    height: auto;
    overflow: auto;
    width: 100%;
}
.orderHistoryProductHeadDiv{
    width: 90%;
    height: auto;
    margin-left: auto;
    margin-right: auto;
}
.orderHistoryOrderPlacedContainer{
    float: left;
}
.orderHistoryOrderPlacedText{
    text-transform: uppercase;
    color: #565959;
    font-size: 12px;
    line-height: 16px;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;

}
.orderHistoryOrderDateText{
    color: #565959;
    font-size: 14px;
    line-height: 20px;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;

}
.orderHistoryOrderTotalContainer{
    float: left;
    margin-left: 4rem;
}
.orderHistoryOrderTotalText{
    text-transform: uppercase;
    color: #565959;
    font-size: 12px;
    line-height: 16px;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}
.orderHistoryOrderPriceText{
    color: #565959;
    font-size: 14px;
    line-height: 20px;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;

}
.orderHistoryOrderShipContainer{
    float: left;
    margin-left: 4rem;
}
.orderHistoryOrderShipText{
    text-transform: uppercase;
    color: #565959;
    font-size: 12px;
    line-height: 16px;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}
.orderHistoryOrderShippingAddressText{
    color: #007185;
    font-size: 14px;
    line-height: 20px;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    cursor: pointer;
}
.orderHistoryOrderShippingAddressText:hover{
    color: #c45500;
    text-decoration: underline;
}
.orderHistoryOrderIdContainer{
    float: left;
    margin-left: 14rem;
}
.orderHistoryOrderIdText{
    text-transform: uppercase;
    color: #565959;
    font-size: 12px;
    line-height: 16px;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}
.orderHistoryOrderDetailsText{
    color: #555;
    font-size: 14px;
    line-height: 20px;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}
.orderHistoryOrderViewDetailsText{
    color: #007185;
    cursor: pointer;
}
.orderHistoryOrderViewDetailsText:hover{
    color: #c45500;
    text-decoration: underline;
}
.orderHistoryOrderInvoiceText{
    color: #007185;
    cursor: pointer;
}
.orderHistoryOrderInvoiceText:hover{
    color: #c45500;
    text-decoration: underline;
}
.orderHistoryProductContentContainer{
    width: 100%;
    height: auto;
}
.orderHistoryProductContentDiv{
    width: 90%;
    margin-left: auto;
    margin-right: auto;
}
.orderHistoryProductOrderStatus{
    font-size: 18px!important;
    line-height: 24px!important;
    font-weight: 700!important;
    color: #0F1111;
}
.orderHistoryProductOrderStatusText{
    color: #565959!important;
    font-family: "Amazon Ember",Arial,sans-serif;
    font-size: 14px;
    line-height: 20px;
}
.orderHistoryProductImage{
    max-height: 100px;
    width: auto;
}
.orderHistoryProductImageDiv{
    margin-top: 2rem;
    max-height: 150px;
    width: auto;
}
.orderHistoryProductNameDiv{
    margin-left: 8rem;
    margin-bottom: 4rem;
}
.orderHistoryProductName{
    margin-top: -5rem;
    font-size: 14px;
    line-height: 20px;
    color: #007185;
    cursor: pointer;
}
.orderHistoryProductName:hover{
    text-decoration: underline;
    cursor: pointer;
    color: #C7511F;
}
.fas{
    color: #555;
}
.dropdown-content{
    position: absolute;
    display: none;
}
.orderHistoryOrderShippingAddressText:hover .dropdown-content{
    display: block;
}
.orderShippingDropDownSection{
    overflow: hidden;
    border: 1px solid #cdcdcd;
    border-color: rgba(0,0,0,.2);
    height: 150px;
    width: 234px;
    border-radius: 4px;
    box-shadow: 0 2px 4px rgb(0 0 0 / 13%);
    background-color: #fff;
    margin-left: -5rem;
}
.orderShippingDropDownDiv{
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 0.5rem;
}
.orderShippingDropDownAddressName{
    font-weight: 700;
    color: #000;
    font-size: 14px;
    line-height: 20px;
}
.orderShippingDropDownAddressAddress{
    color: #000;
    font-size: 14px;
    line-height: 20px;
    margin-top: 0.2rem;
}
.orderShippingDropDownAddressPlace{
    color: #000;
    font-size: 14px;
    line-height: 20px;
    margin-top: 0.2rem;
}
.orderShippingDropDownAddress{
    color: #000;
    font-size: 14px;
    line-height: 20px;
    margin-top: 0.2rem;
}
.orderShippingDropDownAddressCountry{
    color: #000;
    font-size: 14px;
    line-height: 20px;
    margin-top: 0.2rem;
}
.orderShippingDropDownPhone{
    color: #000;
    font-size: 14px;
    line-height: 20px;
    margin-top: 0.2rem;
}