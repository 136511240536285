.alldeals{
  position: relative;
  /* top: 160px; */
  background-color: #f6f5f5;
}

.deals-head {
  font-family: "Noto Sans", sans-serif;
  font-size: 25px;
  text-align: center;
  color: rgb(38, 38, 38);
}

.deal-items {
  display: grid;
  padding-bottom: 120px;
  padding-top: 15px;
  grid-template-columns: repeat(auto-fit, 250px);
  grid-gap: 10px;
  justify-content: center;
}

.card1 {
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-left: 24px;
  margin-top: 15px;
}

.card1-img {
  cursor: pointer;
  width: 130px;
  height: 150px;
  background-color: white;
  box-shadow: rgba(0, 0, 0, 0.04) 0px 3px 5px;
  padding: 50px;
  transition: all 0.5s;
  border-radius: 12px;
  transition: all 0.5s;
}

.card1-price {
  display: flex;
  /* position: relative; */
  bottom: 18px;
  align-items: center;
}

.card1-title {
  margin-top: 15px;
  margin-bottom: 10px;
  font-family: "Noto Sans", sans-serif;
  text-align: left;
  font-size: 16px;
  color: rgb(38, 38, 38);
}

.card1-category {
  margin: 0;
  text-transform: uppercase;
  font-weight: 200;
  color: rgb(50, 50, 50);
  font-size: 12px;
}

.mrp {
  margin-left: 8px;
  text-decoration: line-through;
  font-weight: 600;
  color: rgb(72, 72, 72);
}

.price-off {
  margin-left: 8px;
  font-weight: 700;
  color: rgb(172, 0, 0);
}

.discount {
  font-family: "Noto Sans", sans-serif;
  font-weight: 600;
  font-size: 20px;
}

.view {
  cursor: pointer;
  position: relative;
  bottom: 178px;
  left: 62px;
  border: none;
  background-color: white;
  text-align: center;
  padding: 10px;
  box-shadow: rgba(0, 0, 0, 0.04) 0px 3px 5px;
  font-family: "Noto Sans", sans-serif;
  border-radius: 6px;
  transition: all 0.3s;
  opacity: 0;
  visibility: hidden;
  transition: all 0.18s ease;
}

.view:hover {
  transform: scale(1.06);
}

.card1-data {
  position: relative;
  text-align: left;
}

.card1-img-data:hover .view {
  opacity: 1;
  visibility: visible;
}

.card1-img-data:hover .card1-img {
  filter: brightness(75%);
}

.card1-img-data {
  height: 250px;
}

.add-list {
  width: 24px;
  position: relative;
  bottom: 246px;
  left: 9px;
  transition: all 0.2s ease;
}

.add-list:hover {
  transform: scale(1.16);
}
.add-list:active {
  transform: scale(0.82);
}

.rating-img {
  width: 20px;
}
.rating {
  display: flex;
  align-items: center;
}
.rating-text {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  font-weight: 600;
  margin-left: 6px;
  margin-bottom: 0;
}

/* MEDIA QUERIES  */

@media (max-width: 690px) {
  .deal-items {
    padding-right: 22px;
  }
}

@media (max-width: 420px) {
  .deals-head {
    font-size: 20px;
  }
  .Deals {
    top: 20px;
  }
}
