.adminProductsSection{
    padding-top: 5rem;
    width: 100%;
    height: auto;
    overflow: auto;
    margin-bottom: 2rem;
}
.productAddBtnDiv{
    margin-bottom: 2rem;
    float: left;
}
.departmentAddBtnDiv{
    margin-left: 1rem;
}
.btn-product-option{
    float: left;
}
.btn-delete{
    margin-left: 1rem;
}
.productIcon{
    font-size: 1rem;
}
.adminInputSpace{
    height: 2.5rem;
    border-radius: 3px;
    font-family: Arial,sans-serif;
    font-size: 13px;
    border: 1px solid #d0d0d0;
    border-top: 1px solid #bbb;
    outline-color: gray;
    outline-width: thin;
    margin-left: 50%;
}