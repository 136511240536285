.orders-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  background-color: #f6f5f5;
  padding-top: 50px;
  padding-bottom: 120px;
  height: fit-content;
}

.order {
  display: flex;
  align-items: center;
  background-color: white;
  padding-left: 25px;
  padding-right: 25px;
  font-family: "Poppins";
  font-size: 15px;
  padding-top: 10px;
  padding-bottom: 10px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 3px 0px,
    rgba(0, 0, 0, 0.06) 0px 1px 2px 0px;
  margin-top: 25px;
  border-radius: 10px;
  width: 640px;
}

.order-img {
  width: 50px;
}

.order-text {
  margin-left: 32px;
}

.order-head {
  margin-bottom: 2px;

  font-family: "Noto Sans", sans-serif;
  font-size: 16px;
  color: rgb(38, 38, 38);
}

.order-category {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  text-transform: uppercase;
  font-weight: bolder;
  color: rgb(50, 50, 50);
  font-size: 12px;
}

.nav-link2 {
  color: inherit;
  text-decoration: none;
  width: fit-content;
}

.order-done {
  width: 25px;
}

.order-success {
  display: flex;
  align-items: center;
  color: #013d01;
  font-family: "Poppins";
  padding-left: 8px;
  padding-right: 8px;
  padding-top: 2px;
  padding-bottom: 2px;
  margin-bottom: 15px;
  border-radius: 6px;
  font-size: 15px;
  width: fit-content;
  background-color: #0cd80c62;
}

.order-head-text {
  font-family: "Poppins";
  font-size: 30px;
  margin: 0;
  font-weight: bold;
}

.no-orders-head {
  font-family: "Poppins";
}

.no-orders {
  width: 550px;
}

.head-texts {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.delete-order-btn {
  width: 22px;
  margin-right: 6px;
}

.delete-orders {
  cursor: pointer;
  display: flex;
  align-items: center;
  background-color: rgb(250, 55, 55);
  border: none;
  color: white;
  padding: 8px;
  font-family: "Poppins";
  font-size: 14px;
  font-weight: bold;
  border-radius: 5px;
  transition: all 0.2s ease;
}

.delete-orders:hover {
  transform: scale(1.05);
}

.delete-orders:active {
  transform: scale(0.92);
}

/* MEDIA QUERIES */

@media (max-width: 720px) {
  .order {
    width: 500px;
  }
}

@media (max-width: 600px) {
  .no-orders {
    position: relative;
    right: 15px;
    width: 85vw;
  }
}

@media (max-width: 570px) {
  .order {
    width: 400px;
  }
  .order-head-text {
    font-size: 25px;
  }
}

@media (max-width: 475px) {
  .order {
    width: 300px;
    flex-direction: column;
  }
  .order-img {
    display: none;
  }
  .order-text {
    margin-left: 0px;
  }
  .delete-orders > p {
    display: none;
  }
  .delete-order-btn {
    margin-right: 0px;
  }
  .no-orders {
    top: 28px;
  }
}

@media (max-width: 370px) {
  .order {
    width: 200px;
  }
  .order-head-text {
    font-size: 25px;
  }
  .order-success {
    font-size: 13px;
  }
}

@media (max-width: 330px) {
  .no-orders {
    top: 80px;
  }
}
