@font-face {
  font-family: Credit-Card;
  src: url("../font/CREDC___.ttf");
}

.payment-page {
  background-color: #ffffff;
  margin: 0;
}

.more-data {
  /* padding-top: 160px; */
  padding-bottom: 120px;
  display: flex;
  justify-content: center;
}

.shipping-data,
.payment-data {
  background-color: white;
  height: fit-content;
  padding: 50px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 6px -1px,
    rgba(0, 0, 0, 0.06) 0px 2px 4px -1px;
  border-radius: 10px;
  /* width: 50%; */
}
.payment-data{
  width: 50%;
}
.payment-select{
  background-color: white;
  height: fit-content;
  padding: 20px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 6px -1px,
    rgba(0, 0, 0, 0.06) 0px 2px 4px -1px;
  border-radius: 10px;
}
.payment-select img {
  padding-left: 70%;
}

.payment-data {
  height: fit-content;
  transition: height 0.5s ease;
}

.shipping-head,
.payment-method {
  font-family: "Poppins";
  font-weight: bold;
  font-size: 24px;
}

.payment-method {
  margin-top: 0;
}

.country > input,
.address> select,
.user-contact > input,
.user-name > input,
.user-email > input,
.user-address > input,
.user-pincode > input {
  height: 35px;
  font-family: "Poppins";
  /* padding: 0 8px; */
  font-size: 15px;
  border-radius: 4px;
  margin-bottom: 10px;
  outline: none;
  border: 1.5px solid rgb(174, 174, 174);
  width: 600px;
}

.country > p,
.address > p,
.user-name > p,
.user-contact > p,
.user-email > p,
.user-address > p,
.user-pincode > p {
  font-family: "Poppins";
  font-size: 12px;
  font-weight: bold;
  margin-top: 15px;
  margin-bottom: 5px;
}
.deliverinst{
  width: 90%;
  font-size: 15px;
}

.save-address {
  display: flex;
  align-items: center;
  cursor: pointer;
  border: none;
  background-color: rgb(0, 136, 255);
  padding: 8px;
  padding-left: 20px;
  padding-right: 20px;
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  color: white;
  transition: all 0.3s ease;
  font-size: 17px;
  border-radius: 5px;
  margin-top: 26px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 18px 50px -10px;
  transition: all 0.13s;
}

.save-address:hover {
  transform: scale(1.06);
}

.save-address:active {
  transform: scale(1);
}

.choose-option {
  display: flex;
  flex-direction: column;
}

.cod,
.credit,
.upi {
  font-family: "Poppins";
  margin-top: 10px;
}

.cod > input,
.credit > input,
.upi > input {
  margin-right: 10px;
}

.confirm-btn {
  cursor: pointer;
  border: none;
  background-color: rgb(255, 149, 0);
  padding: 12px;
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  color: white;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 15px -3px,
    rgba(0, 0, 0, 0.05) 0px 4px 6px -2px;
  font-size: 18px;
  border-radius: 6px;
  transition: all 0.13s;
}

.confirm-btn:hover {
  transform: scale(1.06);
}

.confirm-btn:active {
  transform: scale(1);
}

.total-amount {
  font-family: "Poppins";
  display: flex;
  align-items: center;
}

.main-amount {
  margin-left: 8px;
  font-weight: bolder;
  font-size: 19px;
}

.credit-body {
  cursor: pointer;

  background-image: url("../imgs/world.png"),
    linear-gradient(
      74deg,
      rgba(235, 99, 26, 0.81) 11.32%,
      rgb(255, 196, 108) 100%
    );
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  background-blend-mode: color-burn;
  height: 200px;
  display: flex;
  width: 360px;
  flex-direction: column;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 20px 25px -5px,
    rgba(0, 0, 0, 0.04) 0px 10px 10px -5px;
  border-radius: 14px;
  transition: transform 0.25s ease-out;
  margin-top: 40px;
}

.paying-data {
  margin-top: 30px;
}

.first-layer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 15px;
  padding-right: 18px;
}

.credit-chip {
  position: relative;
  right: 34px;
  width: 110px;
}

.card-company {
  width: 50px;
  transition: all 0.8s ease;
}

.middle-layer {
  display: flex;
  align-items: center;
  margin-top: 12px;
  justify-content: center;
}

.account-number,
.holder-name,
.cvv-number,
.exp-date {
  font-family: Credit-Card;
  font-weight: 200;
  color: white;
  font-size: 20px;
  margin-top: 14px;
  background-color: rgba(255, 255, 255, 0);
  border: none;
  outline: none;
}

.holder-name {
  position: absolute;
  left: 14px;
  font-size: 17px;
}

.acc-number-inp::-webkit-inner-spin-button,
.acc-number-inp::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.acc-cvv-inp::-webkit-inner-spin-button,
.acc-cvv-inp::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.acc-exp-inp::-webkit-inner-spin-button,
.acc-exp-inp::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.user-pincode > input::-webkit-inner-spin-button,
.user-pincode > input::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.user-contact > input::-webkit-inner-spin-button,
.user-contact > input::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.last-layer {
  display: flex;
  margin-top: 40px;
  align-items: center;
}

.cvv-number {
  position: absolute;
  right: 84px;
}

.cvv-number,
.exp-date {
  font-size: 14px;
}

.exp-date {
  position: absolute;
  right: 14px;
}

.online-card-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  transition: all 0.5s ease;
  animation: FADEIN 0.5s ease;
}

@keyframes FADEIN {
  0% {
    opacity: 0;
    transform: scale(0.95);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}

.online-card-form {
  margin-top: 30px;
}

.card-head-details {
  font-family: "Poppins";
  font-size: 25px;
  font-weight: bolder;
}

.acc-name > p,
.acc-number > p,
.acc-cvv > p,
.acc-exp > p {
  font-family: "Poppins";
}

.acc-name > input,
.acc-number > input,
.acc-cvv > input,
.acc-exp > input {
  height: 35px;
  font-family: "Poppins";
  padding: 8px;
  font-size: 15px;
  border-radius: 4px;
  outline: none;
  border: 1.5px solid rgb(174, 174, 174);
  width: 355px;
}

.order-id {
  font-family: "Poppins";
  margin-bottom: 25px;
}

.all-data-of-user,
.all-data-of-card {
  display: flex;
}

.user-data2,
.card-data2 {
  margin-left: 30px;
}

input[type="radio"] {
  cursor: pointer;
}
.animation {
  animation: FadeIn 0.35s ease;
  transition: all 0.35s ease;
}

@keyframes FadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

/* MEDIA QUERIES */

@media (max-width: 1040px) {
  .address> select,
  .country > input,
  .user-name > input,
  .user-contact > input,
  .user-email > input,
  .user-address > input,
  .user-pincode > input {
    width: 300px;
  }
  
}

@media (max-width: 1040px) {
  .acc-name > input,
  .acc-number > input,
  .acc-cvv > input,
  .acc-exp > input {
    width: 275px;
  }
}

@media (max-width: 800px) {
  .address> select,
  .country > input,
  .user-name > input,
  .user-contact > input,
  .user-email > input,
  .user-address > input,
  .user-pincode > input {
    width: 270px;
  }
  .deliverinst{

    font-size: 10px;
  }
 
  
}

@media (max-width: 740px) {
  .acc-name > input,
  .acc-number > input,
  .acc-cvv > input,
  .acc-exp > input {
    width: 185px;
  }
  .payment-select img {
    padding-left: 56%;
  }
}

@media (max-width: 630px) {
  .all-data-of-user {
    flex-direction: column;
  }
  .user-data2 {
    margin-left: 0px;
  }
  .all-data-of-card {
    flex-direction: column;
  }
  .card-data2 {
    margin-left: 0px;
  }
  .acc-name > input,
  .acc-number > input,
  .acc-cvv > input,
  .acc-exp > input {
    width: 285px;
  }
  .payment-select img {
    padding-left: 40%;
  }
}

@media (max-width: 565px) {
  .credit-body {
    height: 160px;
    width: 296px;
  }
  .credit-chip {
    right: 26px;
    width: 70px;
  }
  .card-company {
    width: 32px;
  }
  .first-layer {
    padding-left: 15px;
    padding-right: 13px;
    padding-top: 5px;
  }
  .cvv-number,
  .exp-date {
    font-size: 11px;
  }
  .holder-name {
    font-size: 12px;
  }
  .account-number {
    font-size: 14px;
    margin-top: 12px;
  }
  .all-data-of-card {
    flex-direction: column;
  }
  .card-data2 {
    margin-left: 0px;
  }
  .order-place-btn {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .total-amount {
    justify-content: center;
  }
  .payment-select img {
    padding-left: 30%;
  }
}

@media (max-width: 520px) {
  .payment-data {
    padding: 32px;
  }
  .payment-select{
    padding: 32px;
  }
}

@media (max-width: 500px) {
  .payment-data {
    margin-left: 12px;
    margin-right: 12px;
    width: 90%;
  }
  .payment-select{
    margin-left: 12px;
    margin-right: 12px;
    width: 70%;
    
  }
  .payment-select{
    width: 70%;
  }
  .payment-select img {
    padding-left: 10%;
  }

}

@media (max-width: 385px) {
  .credit-body {
    transform: scale(0.8);
  }
  .credit-body {
    font-size: 20px;
  }
  .choose-option {
    font-size: 14px;
  }
  .card-head-details {
    font-size: 22px;
  }
  .all-data-of-card {
    font-size: 13px;
  }
  .payment-data {
    width: 70%;
  }
  .payment-select{
    width: 70%;
  }
  .acc-name > input,
  .acc-number > input,
  .acc-cvv > input,
  .acc-exp > input {
    width: 215px;
  }
  /* .payment-select img {
    padding-left: 30%;
  } */
}

@media (max-width: 365px) {
  .address> select,
  .country > input,
  .user-name > input,
  .user-contact > input,
  .user-email > input,
  .user-address > input,
  .user-pincode > input {
    width: 180px;
    height: 28px;
  }
  .payment-select img {
    padding-left: 20%;
  }

}

@media (max-width: 300px) {

  .acc-name > input,
  .acc-number > input,
  .acc-cvv > input,
  .acc-exp > input {
    width: 180px;
  }
  .total-amount {
    font-size: 14px;
  }
  .main-amount {
    font-size: 15.5px;
  }
  .confirm-btn {
    font-size: 15px;
  }
  .payment-select img {
    padding-left: 30%;
  }
}
